@media (max-width: 767px) {
  .hidden-xs,
  tr.hidden-xs,
  th.hidden-xs,
  td.hidden-xs {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm,
  tr.hidden-sm,
  th.hidden-sm,
  td.hidden-sm {
    display: none !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md,
  tr.hidden-md,
  th.hidden-md,
  td.hidden-md {
    display: none !important;
  }
}
@media (min-width: 1200px) {
  .hidden-lg,
  tr.hidden-lg,
  th.hidden-lg,
  td.hidden-lg {
    display: none !important;
  }
}

@mixin lg {
  @media only screen and (min-width: 1200px){
    @content;
  }
}

@mixin md {
  @media (min-width: 992px) and (max-width: 1199px){
    @content;
  }
}

@mixin sm {
  @media (min-width: 768px) and (max-width: 991px){
    @content;
  }
}

@mixin xs {
  @media only screen and (max-width: 767px){
    @content;
  }
}

@mixin xxs {
  @media only screen and (max-width: 320px){
    @content;
  }
}


