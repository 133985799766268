@import "basics/media";
@import "basics/basicMixins";
@import "basics/helpers";

@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&subset=latin-ext');

$blue : #2C3E50;
$grey: #ECF0F1;


*{
	box-sizing: border-box;
}
body{
	background: $grey;
	font-size: 13px;
	font-family: 'Open Sans', sans-serif;
	color: $blue;
	padding-top: 124px;
	padding-left: 200px;
	transition: padding .4s;
	@media(max-width: 1400px){
		padding-left: 56px;
	}
}

a{
	color: $blue;
}

header{
	background: $grey;
	line-height: 80px;
	//padding: 0 30px;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 9;
	.logo{
		float: left;
		padding: 0 30px;
		img{
			vertical-align: middle;
			height: 35px;
		}
	}
	.acc{
		float: right;
		padding: 0 30px;
		b{
			font-weight: 600;
		}
	}
}

.top-bar{
	height: 44px;
	line-height: 44px;
	background: #fff;
	position: fixed;
	top: 80px;
	left: 0;
	width: 100%;
	z-index: 9;
	padding-left: 250px;
	transition: .4s;
	a{
		display: inline-block;
		line-height: 32px;
		padding: 0 25px;
		font-size: 12px;
		border-radius: 5px;
		transition: .4s;
		&:hover{
			background: $grey;
		}
		&.active{
			background: $grey;
			color: #000;
			font-weight: bold;
		}
	}
	@media(max-width: 1400px){
		padding-left: 106px;
	}
}

nav.main{
	width: 230px;
	background: #fff;
	position: fixed;
	top: 124px;
	left: 0;
	height: calc(100% - 124px);
	padding: 80px 20px 0;
	z-index: 9;
	overflow: hidden;
	transition: .4s;
	a{
		display: block;
		padding: 15px 0;
		line-height: normal;
		border-bottom: 1px solid $grey;
		white-space: pre;
		transition: .4s;
		i{
			font-size: 16px;
			margin-right: 15px;
			transition: .4s;
			color: $blue !important;
		}
		&.active{
			font-weight: bold;
		}
		&:hover{
			opacity: .6;
		}
	}
	@media(max-width: 1400px){
		width: 56px;
		a{
			color: #fff;
			i{
				margin-right: 0;
			}
		}
		&:hover{
			width: 200px;
			a{
				color: $blue;
				i{
					margin-right: 15px;
				}
			}
		}
	}
}

main{
	padding: 90px 0;
	position: relative;
}

.container{
	width: 100%;
	padding: 0 50px;
	max-width: 1600px;
}
select{
	height: 50px;
	background: #fff;
	border-radius: 5px;
	position: relative;
	box-shadow: 0 2px 0 0 rgba(0,0,0,.1);
	margin-bottom: 15px;
	border: 1px solid #E5E5E5;
}

.center {
	margin: auto;
	width: 50%;
	max-width: 400px;
	min-width: 150px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 20px;
}
//div.loader {
//	font-size: 50px;
//}

.spinner {
	animation: spin infinite 5s linear;
	/*You can increase or decrease the timer (5s) to
     increase or decrease the speed of the spinner*/
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.input{
	height: 50px;
	background: #fff;
	border-radius: 5px;
	position: relative;
	box-shadow: 0 2px 0 0 rgba(0,0,0,.1);
	margin-bottom: 15px;
	border: 1px solid #E5E5E5;
	white-space: nowrap;
	overflow: hidden;
	span{
		position: absolute;
		left: 0;
		top: 0;
		line-height: 50px;
		color: #9B9B9B;
		padding-left: 20px;
		transition: .4s;
	}
	input, textarea{
		width: 100%;
		height: 100%;
		padding: 15px 20px 0;
		border: 0;
		background: transparent;
		text-align: left;
	}
	textarea{
		resize: vertical;
		padding: 20px;
		height: 150px;
		min-height: 150px;
	}
	&.text{
		height: auto;
	}
	&.icon{
		i{
			position: absolute;
			left: 15px;
			top: 0;
			line-height: 50px;
			font-size: 18px;
		}
		span, input{
			padding-left: 45px;
		}
	}
	&.date{
		cursor: pointer;
		input{
			cursor: pointer;
		}
		i{
			position: absolute;
			right: 0;
			top: 0;
			line-height: 50px;
			font-size: 18px;
			background: #E1E5E6;
			padding: 0 20px;
		}
		span, input{
			padding-right: 56px;
		}
	}
	&.select{
		cursor: pointer;
		input{
			cursor: pointer;
		}
		ul{
			position: absolute;
			background: #fff;
			top: 55px;
			left: 0;
			right: 0;
			padding: 0;
			border-radius: 5px;
			z-index: 1;
			opacity: 0;
			visibility: hidden;
			border: 1px solid #E5E5E5;
			transition: .4s;
			li{
				list-style-type: none;
				display: block;
				padding: 15px 25px;
				transition: .4s;
				&:hover{
					background: #f7f7f7;
				}
			}
		}
		i{
			position: absolute;
			right: 15px;
			top: 0;
			line-height: 50px;
			font-size: 16px;
		}
		&.clicked{
			ul{
				opacity: 1;
				visibility: visible;
			}
		}
		&.filter{
			span{
				position: static;
			}
			li{
				i{
					line-height: 21px;
				    font-size: 9px;
				    position: relative;
				    top: -1px;
				    right: 0;
				}
				span{
					line-height: normal;
				}
			}
		}
	}
	&.active{
		&:not(.filter){
			span{
				font-size: 11px;
				line-height: 30px;
			}
		}
	}
}

.check-box{
	cursor: pointer;
	line-height: 21px;
	display: inline-block;
	margin-right: 20px;
	div{
		width: 21px;
		height: 21px;
		background: #fff;
		border-radius: 5px;
		position: relative;
		box-shadow: 0 2px 0 0 rgba(0,0,0,.1);
		text-align: center;
		margin-right: 10px;
		display: inline-block;
		vertical-align: middle;
		top: -2px;
    	border: 1px solid #ECF0F1;
		i{
			line-height: 21px;
			font-size: 9px;
			position: relative;
			top: -2px;
			color: #7ab038;
			opacity: 0;
			transition: .4s;
		}
		&.m-0{
			margin-right: 0;
		}
	}
	input{
		display: none;
	}
	&.radio-box{
		div{
			border-radius: 50%;
			border: 1px solid #eee;
			transition: .4s;
			&:after{
				content: '';
				width: 6px;
				height: 6px;
				background: #fff;
				position: absolute;
				top: 7px;
				left: 7px;
				border-radius: 50%;
				transition: .4s;
			}
		}
	}
	&.active{
		div{
			i{
				opacity: 1;
			}
		}
		&.radio-box{
			div{
				background: $blue;
			}
		}
	}
}

.switch{
	cursor: pointer;
	div{
		width: 50px;
    	height: 26px;
    	border-radius: 13px;
		background: #adadad;
		position: relative;
		display: inline-block;
		margin-left: 15px;
		vertical-align: middle;
		box-shadow: 0 2px 0 0 rgba(0,0,0,.1);
		transition: .4s;
		&:after{
			content: '';
			position: absolute;
			width: 20px;
    		height: 20px;
			top: 3px;
			left: 3px;
			background: #fff;
			border-radius: 50%;
			transition: .4s;
		}
	}
	b{
		width: 52px;
		display: inline-block;
	}
	&.active{
		div{
			background: #4ADE79;
			&:after{
				left: 27px;
			}
		}
	}
}

.tabs{
	margin-bottom: 60px;
	float: left;
	width: 100%;
	.tab{
		background: #fff;
		opacity: .5;
		float: left;
		width: 50%;
		line-height: 70px;
		padding: 0 45px;
		position: relative;
		transition: .4s;
		&:after{
			content: '';
			width: 0;
			height: 0;
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
			border-top: 10px solid #fff;

			position: absolute;
			left: 45px;
			bottom: -10px;
			opacity: 0;
		}
		&:hover{
			opacity: .8;
		}
		&.active{
			opacity: 1;
			border-bottom: 2px solid $blue;
			&:after{
				opacity: 1;
			}
		}
	}
}

.create-client{
	text-align: right;
	border-bottom: 1px solid #D3DEE1;
	margin-bottom: 30px;
	button{
		background: #D3DEE1;
		display: inline-block;
		height: 50px;
		border: 0;
		padding: 0 20px;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		transition: .4s;
		i{
			margin-right: 15px;
			font-size: 20px;
			position: relative;
			top: 2px;
		}
		&:hover{
			background: #c6d1d4;
		}
	}
}

.button{
	height: 50px;
	background: $blue;
	color: #fff;
	border-radius: 5px;
	box-shadow: 0 2px 0 0 #6faa30;
	display: block;
	border: 0;
	width: 100%;
	transition: .4s;
	&:hover{
		box-shadow: 0 2px 0 0 #9df346;
	}
}

.upload-button{
	width: 100%;
	height: 40px;
	background: $blue;
	color: #fff;
	border-radius: 5px;
	box-shadow: 0 2px 0 0 rgba(0,0,0,.1);
	position: relative;
	span{
		position: relative;
		z-index: 1;
	}
	i{
		margin-right: 10px;
		position: relative;
		z-index: 1;
	}
	input{
		opacity: 0;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 2;
	}
	.loading{
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 0;
		background: #a1cada;
		transition: 1s;
		&.active{
			width: 100%;
		}
		&.done{
			opacity: 0;
		}
	}
}

.files{
	a{
		line-height: 40px;
		display: inline-block;
		i{
			margin-left: 10px;
			font-size: 20px;
			position: relative;
			top: 2px;
			cursor: pointer;
		}
		&:hover{
			text-decoration: underline;
		}
	}
}

.data-table{
	box-shadow: 0 2px 0 0 rgba(0,0,0,.1);
	td{
		border: 0;
		border-right: 1px solid #D8D8D8;
		padding: 12px 22px !important;
	}
	thead{
		td{
			background: #fff;
			font-weight: 600;
		}
	}
	.odd{
		td{
			background: #F8F8F8;
		}
	}
	.settings{
		cursor: pointer;
		line-height: 23px;
		width: 23px;
		text-align: center;
		background: #2C3E4F;
		border-radius: 5px;
		display: inline-block;
		float: right;
		vertical-align: middle;
		color: #fff;
		transition: .4s;
		&:hover{
			opacity: .6;
		}
	}
	.details{
		border-radius: 50%;
		float: none;
		margin-right: 10px;
	}
	i.file{
		cursor: pointer;
		font-size: 20px;
		display: inline-block;
		vertical-align: middle;
		transition: .4s;
		&:hover{
			opacity: .6;
		}
	}
}

.dataTables_length label, .dataTables_filter label{
	font-weight: 400;
	margin: 0;
}

.dataTables_length{
	margin-bottom: 20px;
}

.dataTables_info, .dataTables_paginate{
	margin-top: 20px;
}

table.dataTable thead th, table.dataTable thead td{
	border-bottom: 2px solid #E5E5E5;
}

table.dataTable.no-footer{
	border: 0;
}

.dataTables_filter input, .dataTables_length select{
	height: 30px;
	background: #fff;
	border-radius: 5px;
	box-shadow: 0 2px 0 0 rgba(0,0,0,.1);
	border: 0;
	padding: 0 10px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover{
	background: $blue;
	color: #fff !important;
	border-radius: 5px;
	box-shadow: 0 2px 0 0 rgba(0,0,0,.1);
	border: 0;
}

.datepicker{
	padding: 15px;
}

i.ok{
	font-size: 20px;
	color: #56B479;
}

.border{
	border: 1px solid #CBCBCB;
	border-radius: 5px;
	margin: 0 15px;
	clear: both;
	float: left;
	width: calc(100% - 30px);
	position: relative;
	.title{
		background: $grey;
		padding: 0 20px;
		position: absolute;
		left: 30px;
		top: -10px;
		line-height: 20px;
		font-size: 13px;
		font-weight: bold;
	}
	.border, &.m-0{
		width: 100%;
	}
}

.white-box{
	background: #fff;
	border: 1px solid #CBCBCB;
	border-radius: 5px;
	position: relative;
	&.balance{
		padding-bottom: 80px;
		box-shadow: 0 2px 0 0 rgba(0,0,0,.1);
		border: 0;
		border-radius: 5px;
		.grey-box{
			line-height: 50px;
			background: #F8F8F8;
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			padding: 0 50px;
			i{
				font-size: 18px;
				margin-right: 15px;
			}
			.v-line{
				height: 25px;
				margin: 0 15px;
			}
		}
	}
}

.add-user{
	i{
		cursor: pointer;
		width: 47px;
		line-height: 47px;
		background: #fff;
		text-align: center;
		box-shadow: 0 2px 0 0 rgba(0,0,0,.1);
		font-size: 18px;
		border-radius: 50%;
		margin-right: 15px;
		transition: .4s;
		&:hover{
			background: $blue;
			color: #fff;
		}
	}
}

.popup{
	position: fixed;
	z-index: -1;
	top: 124px;
	left: 200px;
	bottom: 0;
	right: 0;
	overflow: auto;
	visibility: hidden;
	opacity: 0;
	padding: 5% 0 0;
	transition: .4s;
	.tint{
		background: #000;
		opacity: .85;
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
	}
	.box{
		width: 800px;
		margin: 50px auto;
		left: 0;
		right: 0;
		background: #fff;
		z-index: 1;
		visibility: hidden;
		opacity: 0;
		position: absolute;
		transition: .6s;
		.title{
			background: #fff;
			padding: 0 25px;
			line-height: 34px;
			border-radius: 5px;
			box-shadow: 0 2px 0 0 rgba(0,0,0,.1);
			position: absolute;
			left: 40px;
			top: -17px;
			font-weight: bold;
		}
		.cls{
			position: absolute;
			right: 0;
			top: -40px;
			cursor: pointer;
			color: #fff;
			i{
				margin-left: 15px;
			}
		}
		&:after{
			content: '';
			position: absolute;
			z-index: 1;
			top: -50px;
			left: 0;
			right: 0;
			bottom: 0;
			display: none;
		}
		&.second{
			margin-top: -120px;
		}
		&.active{
			opacity: 1;
			visibility: visible;
			position: relative;
			&.second{
				margin-top: 50px;
			}
		}
		&.passive{
			margin-top: 70px;
			opacity: .4;
			&:after{
				display: block;
			}
		}
	}
	@media (max-width: 1400px){
		left: 56px;
	}
	&.active{
		z-index: 2;
		opacity: 1;
		visibility: visible;
	}
}

.delete{
	cursor: pointer;
	transition: .4s;
	i{
		font-size: 16px;
		width: 40px;
		line-height: 40px;
		text-align: center;
		background: $blue;
		color: #fff;
		margin-right: 10px;
		border-radius: 50%;
	}
	&:hover{
		opacity: .6;
	}
}


.col-xs-24{
	width: 20%;
}

.u-line{
	text-decoration: underline;
}

.v-line{
	width: 1px;
	height: 50px;
	display: inline-block;
	vertical-align: middle;
	background: #D3DEE1;
}
.h-line{
	height: 1px;
	background: #CDCECE;
	clear: both;
}


.a-left{
	text-align: left;
}
.a-right{
	text-align: right;
}
.a-center{
	text-align: center;
}


.m-0{
	margin: 0;
}
.m-r-0{
	margin-right: 0;
}
.m-l-0{
	margin-left: 0;
}


.m-b-10{
	margin-bottom: 10px;
}
.m-b-20{
	margin-bottom: 20px;
}
.m-b-30{
	margin-bottom: 30px;
}
.m-b-40{
	margin-bottom: 40px;
}
.m-b-50{
	margin-bottom: 50px;
}
.m-b-60{
	margin-bottom: 60px;
}
.m-b-70{
	margin-bottom: 70px;
}
.m-b-80{
	margin-bottom: 80px;
}
.m-b-90{
	margin-bottom: 90px;
}


.m-t-10{
	margin-top: 10px;
}
.m-t-20{
	margin-top: 20px;
}
.m-t-30{
	margin-top: 30px;
}
.m-t-40{
	margin-top: 40px;
}
.m-t-50{
	margin-top: 50px;
}
.m-t-60{
	margin-top: 60px;
}


.m-r-10{
	margin-right: 10px;
}
.m-r-20{
	margin-right: 20px;
}
.m-r-30{
	margin-right: 30px;
}


.m-l-10{
	margin-left: 10px;
}
.m-l-20{
	margin-left: 20px;
}
.m-l-30{
	margin-left: 30px;
}


.p-0{
	padding: 0;
}
.p-r-0{
	padding-right: 0;
}
.p-l-0{
	padding-left: 0;
}


.p-10{
	padding: 10px;
}
.p-15{
	padding: 15px;
}
.p-20{
	padding: 20px;
}
.p-30{
	padding: 30px;
}
.p-40{
	padding: 40px;
}
.p-50{
	padding: 50px;
}
.p-60{
	padding: 60px;
}
.p-120{
	padding: 120px;
}

.p-b-10{
	padding-bottom: 10px;
}
.p-b-20{
	padding-bottom: 20px;
}
.p-b-30{
	padding-bottom: 30px;
}
.p-b-40{
	padding-bottom: 40px;
}
.p-b-50{
	padding-bottom: 50px;
}
.p-b-60{
	padding-bottom: 60px;
}


.p-t-10{
	padding-top: 10px;
}
.p-t-15{
	padding-top: 15px;
}
.p-t-20{
	padding-top: 20px;
}
.p-t-30{
	padding-top: 30px;
}
.p-t-40{
	padding-top: 40px;
}
.p-t-50{
	padding-top: 50px;
}
.p-t-60{
	padding-top: 60px;
}

.float-none{
	float: none !important;
}

.status-box {
	height: 12px;
	width: 12px;
	margin-bottom: 15px;
	border-radius: 100%;
	background-color: #4ADE79
}

.plus-button {
	border: 2px solid lightgrey;
	background-color: #fff;
	font-size: 16px;
	height: 2.5em;
	width: 2.5em;
	border-radius: 999px;
	position: relative;

	&:after,
	&:before {
		content: "";
		display: block;
		background-color: grey;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	&:before {
		height: 1em;
		width: 0.2em;
	}

	&:after {
		height: 0.2em;
		width: 1em;
	}
}
